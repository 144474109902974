@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/translucent.css';


/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

// overrides (global)

.vex-sidenav-toolbar__headline {
  font-size:1em !important;
}

.title {
  margin: 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 17px;
  padding: 16px 0;
}

.tippy-box[data-theme=light] {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.125)!important;
}

.container {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  margin-left: unset !important;
  margin-right: unset !important;
  max-width: unset !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label{
  font-size: 1em !important;
}

.bdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(2px);
  height: 100%;
}

.bdrop-soft {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bdrop-soft:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.dialog {
  position: relative;
}

.dialog-button {
  position: absolute;
  color: #ccc;
  width: 24px;
  height: 24px;
}

.first-button {
  @extend .dialog-button;
  right: 0px;
}

.second-button {
  @extend .dialog-button;
  right: 30px;
  cursor: move;
}

.trip-details-info-window-key {
  padding-right: 1em;
}

.info-window-cell {
  line-height: 2em;
  padding: 5px 0;
}

.info-window-key {
  p {
    font-size: 1em;
    font-weight: 600;
  }
}

.info-window-key-nontable {
  color: grey;

  p {
    font-size: 0.8em;
    font-weight: 400;
    margin-top: 1rem;
    text-transform: uppercase;
  }
}

.info-window-value {
  padding-left: 20px;
  p {
    font-weight: 400;
    font-size: 1.2em;
  }
}

.mat-tab-body-wrapper{
  flex-grow: 1;
}

.info-window-value-nontable {
  p {
    font-weight: 400;
    padding-left: 0%;
    font-size: 0.9em;
  }
}

.icon-button-container {
  position: relative;
  margin-left: 0.35rem;
  margin-top: 1rem;
  margin-bottom: -0.25rem;
  button > svg {
    color: blue;
  }
  button:disabled > svg {
    color: grey;
  }
}

.tooltiptext {
  visibility: hidden;
  background-color: rgb(75, 71, 71);
  color: #fff;
  padding: 5px 7px;
  position: absolute;
  z-index: 1;
}

.icon-button-container .tooltiptext {
  margin-top: 25px;
  margin-left: -20px;
}

.icon-button-container:hover .tooltiptext {
  visibility: visible;
}
